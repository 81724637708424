import tenantData from './locales';

import placementIds from './locales/placementIds.twh.nlbe';
import Ssp from './locales/Ssp.twh.nlbe';
import RubiconConfig from './locales/rubiconConfig.twh.nlbe';
import BidAdjustmentsConfig from './locales/bidAdjustments.twh.nlbe';
import GumGumConfig from './locales/gumGumConfig.twh.nlbe';
import setup from './v6/setup';

tenantData.PLACEMENT_IDS = placementIds;
tenantData.SSP_PROVIDERS = Ssp;
tenantData.RUBICON_CONFIG = RubiconConfig;
tenantData.BID_ADJUSTMENTS_CONFIG = BidAdjustmentsConfig;
tenantData.GUMGUM_CONFIG = GumGumConfig;

setup();
