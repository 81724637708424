const placementIds = {
  advertenties_top: {
    default: {
      improvedigital: 13304390,
      rubicon: 1203124,
      appnexus: 15013931,
      ix: 212900,
      criteo: {
        '728x90': 1385997,
      },
    },
    mobile: {
      improvedigital: 13304374,
    },
  },
  l1_top: {
    default: {
      improvedigital: 13304390,
      rubicon: 1203124,
      appnexus: 15013931,
      ix: 212900,
      gumgum: 1, // this is to enable gumgum config for this position
      criteo: {
        '970x250': 1385998,
        '728x90': 1385997,
      },
      tmp: 22709584,
    },
    mobile: {
      improvedigital: 13304374,
      tmp: 22709584,
    },
  },
  l1_left: {
    default: {
      improvedigital: 13304368,
      rubicon: 1203126,
      appnexus: 15013937,
      ix: 212901,
      criteo: {
        '300x250': 1385996,
      },
      tmp: 22709586,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709586,
    },
  },
  l1_right: {
    default: {
      improvedigital: 13304370,
      rubicon: 1203130,
      appnexus: 15013942,
      ix: 213703,
      criteo: {
        '300x250': 1394919,
      },
      tmp: 22709587,
    },
    mobile: {
      improvedigital: 13304375,
      tmp: 22709587,
    },
  },
  lrp_top: {
    default: {
      improvedigital: 13304390,
      rubicon: 1203124,
      appnexus: 15013931,
      ix: 212900,
      gumgum: 1, // this is to enable gumgum config for this position
      criteo: {
        '970x250': '1385998',
        '728x90': '1385997',
        '300x250': '1385996',
        '320x50': '1385999',
        '320x100': '1386000',
        '320x240': '1394910',
      },
      tmp: 22709588,
    },
    mobile: {
      improvedigital: 13304374,
      tmp: 22709588,
    },
  },
  lrp_bot: {
    default: {
      improvedigital: 13304371,
      rubicon: 1203132,
      appnexus: 15013982,
      ix: 214508,
      criteo: {
        '970x250': 1394915,
        '728x90': 1394934,
      },
      tmp: 22709669,
    },
    mobile: {
      improvedigital: 13304376,
      tmp: 22709669,
    },
  },
  lrp_gv_mid: {
    default: {
      improvedigital: 13304372,
      rubicon: 1203134,
      appnexus: 15013983,
      ix: 213706,
      criteo: {
        '336x280': 1386004,
        '300x250': 1394920,
      },
      tmp: 22709633,
    },
    mobile: {
      improvedigital: 13304377,
      tmp: 22709633,
    },
  },
  lrp_left: {
    default: {
      improvedigital: 13304368,
      rubicon: 1203126,
      appnexus: 15013937,
      ix: 212901,
      criteo: {
        '120x600': 1386001,
        '160x600': 1386002,
        '300x250': 1394921,
        '300x600': 1386003,
      },
      tmp: 22709590,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709590,
    },
  },
  lrp_left1: {
    default: {
      improvedigital: 13304369,
      rubicon: 1203128,
      appnexus: 15013939,
      ix: 213702,
      criteo: {
        '120x600': 1394917,
        '160x600': 1394918,
        '300x250': 1394922,
        '300x600': 1394923,
      },
      tmp: 22709591,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709591,
    },
  },
  lrp_mid: {
    default: {
      improvedigital: 13304372,
      rubicon: 1203134,
      appnexus: 15013983,
      ix: 213706,
      criteo: {
        '728x90': 1394935,
        '300x250': 1394922,
      },
      tmp: 22709650,
    },
    mobile: {
      improvedigital: 13304377,
      tmp: 22709650,
    },
  },
  home_top: {
    default: {
      improvedigital: 13304390,
      rubicon: 1203124,
      appnexus: 15013931,
      ix: 212900,
      gumgum: 1, // this is to enable gumgum config for this position
      criteo: {
        '970x250': 1385998,
        '728x90': 1385997,
        '300x250': 1385996,
        '320x50': 1385999,
        '320x100': 1386000,
        '320x240': 1394910,
      },
      tmp: 22709592,
    },
    mobile: {
      improvedigital: 13304374,
      top: 22709592,
    },
  },
  vip_right: {
    default: {
      improvedigital: 13304370,
      rubicon: 1203130,
      appnexus: 15013942,
      ix: 213703,
      criteo: {
        '300x600': 1386003,
        '300x250': 1394919,
        '320x50': 1385999,
        '320x100': 1386000,
        '320x240': 1394910,
      },
      tmp: 22709593,
    },
    mobile: {
      improvedigital: 13304375,
      tmp: 22709593,
    },
  },
  vip_right1: {
    default: {
      improvedigital: 22182049,
      rubicon: 1508134,
      appnexus: 17957606,
      criteo: {
        '120x600': 1386001,
        '160x600': 1386002,
        '300x250': 1385996,
        '300x600': 1491288,
      },
      ix: 436346,
      tmp: 22709597,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709597,
    },
  },
  vip_top: {
    default: {
      improvedigital: 13304390,
      rubicon: 1203124,
      appnexus: 15013931,
      ix: 212900,
      criteo: {
        '970x250': 1385998,
        '728x90': 1385997,
      },
      tmp: 22709598,
    },
    mobile: {
      improvedigital: 13304374,
      tmp: 22709598,
    },
  },
  vip_mid: {
    default: {
      improvedigital: 22078758,
      rubicon: 1383302,
      appnexus: 16716985,
      criteo: {
        '320x50': 1394912,
        '468x60': 1429552,
      },
      ix: 397150,
      tmp: 22709670,
    },
    mobile: {
      improvedigital: 22078758,
      tmp: 22709670,
    },
  },
  vip_left: {
    default: {
      improvedigital: 13304368,
      rubicon: 1203126,
      appnexus: 15013937,
      ix: 212901,
      criteo: {
        '120x600': 1394917,
        '160x600': 1394918,
        '300x250': 1394920,
        '300x600': 1394923,
      },
      tmp: 22709599,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709599,
    },
  },
  vip_bot: {
    default: {
      improvedigital: 13304371,
      rubicon: 1203132,
      appnexus: 15013982,
      ix: 214508,
      criteo: {
        '728x90': 1394935,
      },
      tmp: 22709653,
    },
    mobile: {
      improvedigital: 13304376,
      tmp: 22709653,
    },
  },
  vip_map_top: {
    default: {
      improvedigital: 21997265,
      rubicon: 1250014,
      appnexus: 15583125,
      ix: 257949,
      criteo: {
        '728x90': 1394936,
      },
      tmp: 22709600,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709600,
    },
  },
  vip_img_bot: {
    default: {
      improvedigital: 21997264,
      rubicon: 1250008,
      appnexus: 15583123,
      ix: 357948,
      criteo: {
        '728x90': 1394937,
      },
      tmp: 22709673,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709673,
    },
  },
  soi_top: {
    default: {
      improvedigital: 13304390,
      rubicon: 1203124,
      appnexus: 15013931,
      ix: 212900,
      criteo: {
        '728x90': 1385997,
        '300x250': 1385996,
        '320x50': 1385999,
        '320x100': 1386000,
        '320x240': 1394910,
      },
      tmp: 22709601,
    },
    mobile: {
      improvedigital: 13304374,
      tmp: 22709601,
    },
  },
};

export default placementIds;
